/* Preload links */
document.addEventListener("DOMContentLoaded", function () {
	const links = document.querySelectorAll("a[href^='http']:not([target])");

	links.forEach((link) => {
		link.addEventListener("mouseenter", function () {
			const url = link.href;
			if (!document.querySelector(`link[rel="prefetch"][href="${url}"]`)) {
				const prefetch = document.createElement("link");
				prefetch.rel = "prefetch";
				prefetch.href = url;
				document.head.appendChild(prefetch);
			}
		});
	});
});

/* Show menu */
const showMenu = (toggleId, navId) => {
	const toggle = document.getElementById(toggleId),
		nav = document.getElementById(navId);

	toggle.addEventListener("click", () => {
		nav.classList.toggle("show-menu");
		toggle.classList.toggle("show-icon");
		document.body.classList.toggle(
			"no-scroll",
			nav.classList.contains("show-menu")
		);
	});

	// Hide menu when a menu item is clicked
	document.querySelectorAll(".menu-item a, a.nav__logo").forEach((item) => {
		item.addEventListener("click", (e) => {
			const targetID = item.getAttribute("href");

			// If navigating from another page and has hash
			if (window.location.pathname !== "/" && targetID.startsWith("#")) {
				e.preventDefault();
				sessionStorage.setItem("scrollToHash", targetID);

				// Add delay before closing menu
				setTimeout(() => {
					nav.classList.remove("show-menu");
					toggle.classList.remove("show-icon");
				}, 500);
				document.body.classList.remove("no-scroll");
				window.location.href = "/";
			} else {
				// Immediate close for same-page navigation
				nav.classList.remove("show-menu");
				toggle.classList.remove("show-icon");
				document.body.classList.remove("no-scroll");
			}
		});
	});
};

// Smooth scroll with offset (-88px)
function scrollToTarget(targetID) {
	if (!targetID) return; // Ensure targetID is not empty

	const targetElement = document.querySelector(targetID);
	if (targetElement) {
		const offset = -88;
		const targetPosition =
			targetElement.getBoundingClientRect().top + window.scrollY + offset;

		gsap.to(window, {
			duration: 1,
			scrollTo: targetPosition,
			ease: "power2.inOut",
		});

		setTimeout(() => {
			history.replaceState(null, null, targetID);
		}, 800);
	}
}

// Handle scrolling after navigating from another page
window.addEventListener("load", () => {
	let hash = window.location.hash || sessionStorage.getItem("scrollToHash");
	if (hash && document.querySelector(hash)) {
		sessionStorage.removeItem("scrollToHash"); // Clear stored hash
		setTimeout(() => {
			scrollToTarget(hash);
		}, 1000); // Delay slightly to ensure correct position
	}
});

showMenu("nav-toggle", "nav-menu");

/* Stop transition on resize */
(function () {
	const classes = document.body.classList;
	let timer = 0;
	window.addEventListener("resize", function () {
		if (timer) {
			clearTimeout(timer);
			timer = null;
		} else classes.add("stop-transitions");

		timer = setTimeout(() => {
			classes.remove("stop-transitions");
			timer = null;
		}, 100);
	});
})();

/* Lazy load */
document.addEventListener("DOMContentLoaded", function () {
	let lazyBackgrounds = document.querySelectorAll(".lazyload-bg");
	lazyBackgrounds.forEach((bg) => {
		let observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.style.backgroundImage = `url(${entry.target.dataset.bg})`;
					observer.unobserve(entry.target);
				}
			});
		});
		observer.observe(bg);
	});
});

/* Smooth scroll */
const lenis = new Lenis({
	lerp: 0.1, // Adjust for smoothness (0.1 = ultra smooth, 0.6 = normal)
	wheelMultiplier: 1, // Sensitivity
	smooth: true,
});

// Update GSAP ScrollTrigger
function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

// Synchronize Lenis scrolling with GSAP's ScrollTrigger plugin
lenis.on("scroll", ScrollTrigger.update);

// Add Lenis's requestAnimationFrame (raf) method to GSAP's ticker
// This ensures Lenis's smooth scroll animation updates on each GSAP tick
gsap.ticker.add((time) => {
	lenis.raf(time * 1000); // Convert time from seconds to milliseconds
});

// Disable lag smoothing in GSAP to prevent any delay in scroll animations
gsap.ticker.lagSmoothing(0);

/* Testimonials */
document.addEventListener("DOMContentLoaded", function () {
	const swiper = new Swiper(".swiper", {
		slidesPerView: "auto", // Show multiple slides
		spaceBetween: 20, // Space between slides
		loop: true, // Enable infinite scrolling
		loopedSlides: 3, // Ensures smooth loop in both directions
		loopAdditionalSlides: 1, // Prevents disappearing issue
		speed: 1000, // Smooth transition speed
		autoplay: {
			delay: 8000, // Example delay time
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		grabCursor: true, // Better UX
		slideToClickedSlide: false, // Prevent unwanted jumps
		allowTouchMove: true, // Enable dragging/swiping
		resistanceRatio: 0, // Prevent sluggish drag behavior
		centeredSlides: true, // Keep the active slide centered
		slidesPerGroup: 1, // Move only ONE slide per click/swipe
		loopFillGroupWithBlank: false, // Prevents blank slides at the end
		rewind: false, // Ensures looping instead of restarting from the beginning
	});
});

/* Equal heights for testimonials */
document.addEventListener("DOMContentLoaded", function () {
	function setEqualHeight() {
		let maxHeight = 0;
		document.querySelectorAll(".swiper-slide").forEach((slide) => {
			slide.style.height = "auto"; // Reset height
			let slideHeight = slide.offsetHeight;
			if (slideHeight > maxHeight) {
				maxHeight = slideHeight;
			}
		});
		document.querySelectorAll(".swiper-slide").forEach((slide) => {
			slide.style.height = maxHeight + "px";
		});
	}

	setEqualHeight(); // Run once
	window.addEventListener("resize", setEqualHeight); // Adjust on resize
});

/*====================== INITIALIZE GSAP ======================*/
gsap.registerPlugin(ScrollTrigger);

// Function to check if elements exist
function elementsExist(selectors) {
	return selectors.every((selector) => document.querySelector(selector));
}

// Check if the screen width is 1367px or more
const mediaQuery = window.matchMedia("(min-width: 1368px)");

function handleScreenSizeChange(event) {
	if (event.matches) {
		initHomeBlocks(); // Run your script if screen width is >= 1368px
	} else {
		cleanupHomeBlocks(); // Optional: Clean up if the screen gets larger
	}
}

// Run once on page load
if (mediaQuery.matches) {
	initHomeBlocks();
} else {
	cleanupHomeBlocks(); // Optional: Clean up if the screen gets larger
}

// Listen for screen width changes
mediaQuery.addEventListener("change", handleScreenSizeChange);

/*======================== ALL RESOLUTIONS ======================*/

document.addEventListener("DOMContentLoaded", function () {
	function isElementInViewport(element) {
		const rect = element.getBoundingClientRect();
		return (
			rect.top >= -100 &&
			rect.left >= 0 &&
			rect.bottom <=
				(window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	function scrollToTarget(targetID) {
		if (!targetID) return; // Ensure targetID is not empty

		const targetElement = document.querySelector(targetID);
		if (targetElement) {
			const offset = -88;
			const targetPosition =
				targetElement.getBoundingClientRect().top + window.scrollY + offset;

			gsap.to(window, {
				duration: 1,
				scrollTo: targetPosition,
				ease: "power2.inOut",
			});

			setTimeout(() => {
				history.replaceState(null, null, targetID);
			}, 1000);
		}
	}

	function checkHashVisibility() {
		if (window.location.hash) {
			const targetElement = document.querySelector(window.location.hash);
			if (targetElement && !isElementInViewport(targetElement)) {
				history.replaceState("", document.title, window.location.pathname);
			}
		}
	}

	window.addEventListener("scroll", checkHashVisibility);

	document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
		anchor.addEventListener("click", function (e) {
			e.preventDefault();
			const targetID = this.getAttribute("href");

			if (!targetID || targetID === "#") return; // Prevent empty selector error

			if (window.location.pathname !== "/") {
				sessionStorage.setItem("scrollToHash", targetID); // Store hash in sessionStorage
				window.location.href = "/" + targetID;
				return;
			}

			if (window.location.hash === targetID) {
				return;
			}

			scrollToTarget(targetID);
		});
	});

	// Handle scrolling after navigating from another page
	window.addEventListener("load", function () {
		window.scrollTo(0, 0);
		let hash = window.location.hash || sessionStorage.getItem("scrollToHash");
		if (hash && document.querySelector(hash)) {
			sessionStorage.removeItem("scrollToHash"); // Clear stored hash
			setTimeout(() => {
				scrollToTarget(hash);
			}, 1000);
		}
	});
});

// Create timeline for header-block animations only if the header block exists
const headerBlock = document.querySelector(".header-block");
if (headerBlock) {
	const headerBlockTl = gsap.timeline({
		scrollTrigger: {
			trigger: ".header-block",
			start: "top center",
			end: "bottom center",
			scrub: false,
			once: true,
		},
	});

	// Initial state for all backgrounds
	const imageBackgrounds = [
		".image-background-1",
		".image-background-2",
		".image-background-3",
	];
	if (elementsExist(imageBackgrounds)) {
		gsap.set(imageBackgrounds, {
			yPercent: 100,
			opacity: 0,
		});
	}

	// Initial state for stars
	const stars = [".stars2"];
	if (elementsExist(stars)) {
		gsap.set(stars, {
			opacity: 0.3,
		});

		const stars2 = document.querySelector(".stars2");
		if (stars2) gsap.set(stars2, { x: 50 });

		// Modify timeline to show stars first and add overshoot animation for image-background-1
		headerBlockTl.to(stars, {
			x: 0,
			opacity: 0.4,
			duration: 5,
			ease: "power2.out",
		});
	}

	// Add animations for image backgrounds if they exist
	if (elementsExist(imageBackgrounds)) {
		headerBlockTl
			.to(
				imageBackgrounds,
				{
					yPercent: 0,
					opacity: 1,
					duration: 1,
					stagger: 0.2,
					ease: "power2.out",
				},
				"<"
			)
			.to(
				".image-background-1",
				{
					yPercent: -20,
					opacity: 1,
					duration: 2,
					ease: "power2.out",
				},
				"<"
			)
			.to(
				".image-background-1",
				{
					yPercent: 0,
					duration: 2,
					ease: "elastic.out(1, 0.5)",
				},
				"-=3.5"
			);
	}

	// Adjust pulsating effect for stars if they exist
	if (elementsExist(stars)) {
		stars.forEach((selector) => {
			gsap.to(selector, {
				opacity: "random(0.5, 1)",
				duration: "random(2, 4)",
				ease: "sine.inOut",
				repeat: -1,
				yoyo: true,
				delay: 0.8,
			});
		});
	}

	// Add subtle random wiggle to each background if they exist
	if (elementsExist(imageBackgrounds)) {
		imageBackgrounds.forEach((selector) => {
			gsap.to(selector, {
				x: "random(-25, 25)",
				y: "random(-15, 15)",
				rotation: "random(-2, 2)",
				duration: "random(3, 5)",
				ease: "sine.inOut",
				repeat: -1,
				yoyo: true,
			});
		});
	}

	// Add wiggle to stars if they exist
	if (elementsExist(stars)) {
		stars.forEach((selector, i) => {
			gsap.to(selector, {
				x: `random(-5, 5)`,
				y: `random(-8, 8)`,
				rotation: `random(-0.5, 0.5)`,
				duration: "random(6, 10)",
				ease: "sine.inOut",
				repeat: -1,
				yoyo: true,
				delay: i * 0.5,
			});
		});
	}

	const headerLines = document.querySelector(".header-block .header-lines");

	if (headerLines) {
		gsap.fromTo(
			headerLines,
			{ opacity: 0, scale: 1.5 },
			{
				opacity: 0.6,
				scale: 1.05,
				duration: 3,
				ease: "power2.out",
				onComplete: pulsate,
			}
		);

		function pulsate() {
			gsap.to(headerLines, {
				opacity: 0.1,
				scale: 1.2,
				duration: 5,
				repeat: -1,
				yoyo: true,
				ease: "power1.inOut",
			});
		}
	}

	const footerLines = document.querySelector("footer .footer-lines");

	if (footerLines) {
		gsap.fromTo(
			footerLines,
			{ opacity: 0, scale: 1.5 },
			{
				opacity: 0.4,
				scale: 1,
				duration: 3,
				ease: "power2.out",
				onComplete: () => pulsateF(footerLines), // Pass the element reference
			}
		);

		function pulsateF(element) {
			gsap.to(element, {
				opacity: 0.1,
				scale: 1.05,
				duration: 7,
				repeat: -1,
				yoyo: true,
				ease: "power1.inOut",
			});
		}
	}

	// Check if we are on the homepage
	const isHome = document.body.classList.contains("home");

	// Select the header elements
	const headerBlockH1 = document.querySelector(".header-block h1");
	const textDecoration = document.querySelector(".text-decoration");

	if (headerBlockH1) {
		if (isHome) {
			// Homepage Animation (Clip Path Reveal)
			gsap.set(headerBlockH1, {
				opacity: 0,
				clipPath: "inset(0 0 100% 0)",
			});

			gsap.to(headerBlockH1, {
				opacity: 1,
				clipPath: "inset(0 0 0% 0)",
				duration: 0.7,
				ease: "power1.inOut",
				onComplete: () => {
					if (textDecoration) {
						gsap.set(textDecoration, { display: "inline-flex" });
					}
				},
			});
		} else {
			// Other Pages Animation (Slide Down)
			gsap.set(headerBlockH1, {
				opacity: 0,
				y: -50, // Move up initially
			});

			gsap.to(headerBlockH1, {
				opacity: 1,
				y: 0, // Slide down
				duration: 0.7,
				ease: "power1.inOut",
				onStart: () => {
					// Ensure .text-decoration appears on other pages too
					if (textDecoration) {
						gsap.set(textDecoration, { display: "inline-flex" });
					}
				},
			});
		}
	}

	if (textDecoration) {
		gsap.set(textDecoration, {
			opacity: 0,
			display: "none",
		});

		headerBlockTl.to(
			textDecoration,
			{
				opacity: 1,
				duration: 0.7,
				ease: "power2.out",
			},
			1
		);
	}

	const headerBlockP = document.querySelector(".home .header-block p");
	if (headerBlockP) {
		gsap.set(headerBlockP, {
			y: -30,
			opacity: 0,
		});

		headerBlockTl.to(
			headerBlockP,
			{
				y: 0,
				opacity: 1,
				duration: 1,
				ease: "power2.out",
			},
			2
		);
	}

	const contactButton = document.querySelector(".contact-button");
	if (contactButton) {
		gsap.set(contactButton, {
			y: -20,
			opacity: 0,
		});

		headerBlockTl.to(
			contactButton,
			{
				y: 0,
				opacity: 1,
				duration: 0.8,
				ease: "back.out(1.2)",
			},
			2
		);
	}

	const contactNote = document.querySelector(".contact-note");
	if (contactNote) {
		gsap.set(contactNote, {
			y: -20,
			opacity: 0,
		});

		headerBlockTl.to(
			contactNote,
			{
				y: 0,
				opacity: 1,
				duration: 0.8,
			},
			2.3
		);
	}
}

// Initialize section title animations
document.querySelectorAll(".section-title").forEach((titleContainer) => {
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: titleContainer,
			start: "top bottom-=10%",
			end: "bottom center",
			scrub: 1,
		},
	});

	const h3 = titleContainer.querySelector("h3");
	const h4 = titleContainer.querySelector("h4");
	if (h3) {
		tl.fromTo(
			h3,
			{
				opacity: 0,
				y: 20,
			},
			{
				opacity: 1,
				y: 0,
				duration: 0.5,
			}
		);
	}
	if (h4) {
		tl.fromTo(
			h4,
			{
				opacity: 0,
				y: 15,
			},
			{
				opacity: 1,
				y: 0,
				duration: 0.5,
			},
			"-=0.3"
		);
	}
});

// Animate all headings
document
	.querySelectorAll("h1, h2, h3, h4, h5, h6, p, .title-wrapper img")
	.forEach((heading) => {
		if (
			heading &&
			!heading.closest(
				".home-blocks, .header-block, .accordion, .carousel-container, .wpcf7, .team-member"
			)
		) {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: heading,
					start: "top bottom-=10%",
					end: "bottom center",
					scrub: 1,
				},
			});

			tl.fromTo(
				heading,
				{ opacity: 0, y: 40 },
				{ opacity: 1, y: 0, duration: 0.5 }
			);
		}
	});

// Animate .customer img to slide from the right
document.querySelectorAll(".customer-img").forEach((img) => {
	if (img) {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: img,
				start: "top bottom-=10%",
				end: "bottom center",
				scrub: 1,
			},
		});

		tl.fromTo(
			img,
			{ opacity: 0, y: 20 },
			{ opacity: 1, y: 0, duration: 0.7, ease: "power2.out" }
		);
	}
});

// Animate primary programs and customer images
const primaryPrograms = document.querySelector(".primary-programs");
if (primaryPrograms) {
	gsap.fromTo(
		".primary-programs figure, .customer-img",
		{ opacity: 0, yPercent: 0 },
		{
			opacity: 1,
			yPercent: 0,
			duration: 0.7,
			ease: "power2.out",
			stagger: 0.2,
			scrollTrigger: {
				trigger: ".primary-programs",
				start: "top bottom-=10%",
				end: "bottom center",
				scrub: 1,
			},
		}
	);
}

// Animate logos
gsap.utils.toArray(".logos figure").forEach((figure, index) => {
	gsap.from(figure, {
		opacity: 0,
		y: 30,
		duration: 0.6,
		ease: "power2.out",
		delay: index * 0.15,
		scrollTrigger: {
			trigger: figure,
			start: "top 90%",
			toggleActions: "play none none none",
		},
	});
});

// Initialize circle positions and animations
function initCircles() {
	const topArea = document.querySelector(".circles.top");
	const bottomArea = document.querySelector(".circles.bottom");

	function randomPosition(el, container) {
		const maxX = container.offsetWidth - el.offsetWidth;
		const maxY = container.offsetHeight - el.offsetHeight;
		const padding = 60;

		let x, y, overlapping;
		do {
			overlapping = false;
			x = Math.random() * (maxX - padding);
			y = Math.random() * (maxY - padding);

			container.querySelectorAll(".circle").forEach((circle) => {
				if (circle !== el) {
					const rect1 = {
						x,
						y,
						width: el.offsetWidth,
						height: el.offsetHeight,
					};
					const rect2 = circle.getBoundingClientRect();
					if (
						Math.abs(rect1.x - rect2.x) < padding &&
						Math.abs(rect1.y - rect2.y) < padding
					) {
						overlapping = true;
					}
				}
			});
		} while (overlapping);

		el.style.left = `${x}px`;
		el.style.top = `${y}px`;
		el.style.opacity = (Math.random() * 0.7 + 0.3).toString();

		gsap.to(el, {
			x: `random(-80, 80)`,
			y: `random(-80, 80)`,
			duration: "random(5, 7)",
			ease: "sine.inOut",
			repeat: -1,
			yoyo: true,
		});
	}

	if (topArea) {
		document.querySelectorAll(".circles.top .circle").forEach((circle) => {
			randomPosition(circle, topArea);
		});
	}

	if (bottomArea) {
		document.querySelectorAll(".circles.bottom .circle").forEach((circle) => {
			randomPosition(circle, bottomArea);
		});
	}
}

// Call after page load
window.addEventListener("load", initCircles);

// Animate center group and values
document.addEventListener("DOMContentLoaded", function () {
	const centerGroupP = document.querySelectorAll(".center-group p");
	const values = document.querySelectorAll(".values");
	if (centerGroupP.length > 0 || values.length > 0) {
		gsap.from(".center-group p, .values", {
			y: -50,
			opacity: 0,
			duration: 1,
			ease: "power2.out",
			stagger: 0.2,
		});
	}
});

/*====================== DESKTOP RESOLUTION ======================*/

// Only run the code if the screen width is 1368px or more
function initHomeBlocks() {
	//console.log('Desktop')

	let currentContent = document.querySelector(".month-content.active");
	let currentButton = document.querySelector(".month.active");
	let autoplayInterval;
	let isPaused = false;
	let monthsReady = false;

	function nextMonth() {
		const months = document.querySelectorAll(".month");
		const currentIndex = Array.from(months).indexOf(currentButton);
		const nextIndex = (currentIndex + 1) % months.length;
		const nextButton = months[nextIndex];
		const target = document.querySelector(
			nextButton.getAttribute("data-target")
		);
		showContent(target, nextButton);
	}

	function startAutoplay() {
		if (!isPaused) {
			autoplayInterval = setInterval(nextMonth, 5000);
		}
	}

	function stopAutoplay() {
		clearInterval(autoplayInterval);
	}

	function hideContent(content) {
		if (!content) return;

		const boxes = content.querySelectorAll(".box");
		boxes.forEach((box) => {
			box.classList.remove("visible");
			box.style.transform = box.classList.contains("top")
				? "translateY(-20px)"
				: "translateY(20px)";
		});

		setTimeout(() => {
			content.style.display = "none";
			content.classList.remove("active");
		}, 700);
	}

	function showContent(content, button) {
		if (!content) return;

		if (currentButton === button && currentContent === content) {
			return;
		}

		if (currentContent && currentContent !== content) {
			hideContent(currentContent);
			currentButton.classList.remove("active");
		}

		content.style.display = "block";
		content.classList.add("active");
		button.classList.add("active");

		const boxes = content.querySelectorAll(".box");
		boxes.forEach((box, index) => {
			box.style.opacity = "0";
			box.style.transform = box.classList.contains("top")
				? "translateY(-20px)"
				: "translateY(20px)";

			requestAnimationFrame(() => {
				setTimeout(() => {
					box.classList.add("visible");
				}, index * 400);
			});
		});

		currentContent = content;
		currentButton = button;
	}

	// Add hover handlers to container
	const container = document.querySelector(".home section");
	if (container) {
		container.addEventListener("mouseenter", () => {
			isPaused = true;
			stopAutoplay();
		});
		container.addEventListener("mouseleave", () => {
			isPaused = false;
			startAutoplay();
		});
	}

	// Initialize event handlers with flag
	document.querySelectorAll(".month").forEach((button) => {
		button.addEventListener("mouseenter", () => {
			if (!monthsReady) return;

			const target = document.querySelector(button.getAttribute("data-target"));
			if (target) {
				stopAutoplay();
				showContent(target, button);
			}
		});
	});

	// Initialize month reveal animation with earlier start
	const section1 = document.querySelector(".section1");
	if (section1) {
		const monthsTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: ".section1",
				start: "top bottom-=30%",
				end: "top center",
				scrub: 1.5,
				onEnter: () => {
					document
						.querySelectorAll(
							'.month:not([data-target=".month1"]), .month-content:not(.month1)'
						)
						.forEach((el) => {
							el.classList.remove("active");
							if (el.classList.contains("month-content")) {
								el.style.display = "none";
							}
						});
				},
			},
		});

		document.querySelectorAll(".month").forEach((month, index) => {
			monthsTimeline.fromTo(
				month,
				{
					opacity: 0,
					y: 20,
				},
				{
					opacity: 1,
					y: 0,
					duration: 0.5,
					onComplete: () => {
						if (index === document.querySelectorAll(".month").length - 1) {
							monthsReady = true;
							showContent(
								document.querySelector(".month1"),
								document.querySelector('.month[data-target=".month1"]')
							);
							startAutoplay();
						}
					},
				},
				index * 0.2
			);
		});
	}

	// Update section animations with modified reveal
	document
		.querySelectorAll(
			".section2 .content-container, .section3 .content-container"
		)
		.forEach((container) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: "top 90%",
					end: "top 30%",
					scrub: 1,
					once: true,
				},
			});

			tl.fromTo(
				container,
				{
					clipPath: "inset(0 100% 0 0)",
					opacity: 0.0,
				},
				{
					clipPath: "inset(0 0% 0 0)",
					opacity: 1,
					duration: 5,
					ease: "power2.inOut",
				}
			);

			const blocks = container.querySelectorAll(".block-item");
			blocks.forEach((block, index) => {
				tl.fromTo(
					block,
					{ x: 100, opacity: 0 },
					{
						x: 0,
						opacity: 1,
						duration: 0.3,
						ease: "power2.out",
					},
					">-0.2"
				);
			});
		});
}

/*====================== MOBILE RESOLUTION ======================*/

function cleanupHomeBlocks() {
	document.addEventListener("DOMContentLoaded", function () {
		gsap.registerPlugin(ScrollTrigger);

		let blockContainer = document.querySelector(".block-container");
		if (!blockContainer) return;

		let months = document.querySelectorAll(".month");
		let monthContents = document.querySelectorAll(".month-content");
		let autoplayInterval;
		let isPaused = false;

		function activateMonth(index) {
			let targetMonth = months[index];
			let targetContent = document.querySelector(targetMonth.dataset.target);

			if (!targetContent) return;

			// Reset all months and contents
			document
				.querySelectorAll(".month")
				.forEach((el) => el.classList.remove("active"));
			document.querySelectorAll(".month-content").forEach((el) => {
				el.classList.remove("active");
				el.style.opacity = "0.5";
			});

			// Activate new month and content
			targetMonth.classList.add("active");
			targetContent.classList.add("active");
			targetContent.style.opacity = "1";

			// Animate boxes only
			gsap.fromTo(
				targetContent.querySelectorAll(".box"),
				{ opacity: 0 },
				{
					opacity: 1,
					duration: 0.3,
					stagger: 0.1,
					ease: "power1.out",
				}
			);
		}

		// Event handlers
		monthContents.forEach((content, index) => {
			content.addEventListener("click", () => {
				stopAutoplay();
				activateMonth(index);
			});
		});

		months.forEach((month, index) => {
			month.addEventListener("click", () => {
				stopAutoplay();
				activateMonth(index);
			});
		});

		// Autoplay functions
		function startAutoplay() {
			if (!isPaused && !autoplayInterval) {
				autoplayInterval = setInterval(nextMonth, 5000);
			}
		}

		function stopAutoplay() {
			clearInterval(autoplayInterval);
			autoplayInterval = null;
			isPaused = true;
		}

		function nextMonth() {
			const currentIndex = Array.from(months).findIndex((m) =>
				m.classList.contains("active")
			);
			activateMonth((currentIndex + 1) % months.length);
		}

		// Initialize on scroll
		ScrollTrigger.create({
			trigger: ".block-container",
			start: "top 80%",
			once: true,
			onEnter: () => {
				activateMonth(0);
				startAutoplay();
			},
		});
	});

	// Update section animations
	document.querySelectorAll(".block-item").forEach((block, index) => {
		const fromX = (index + 1) % 2 === 1 ? -100 : 100;
		gsap.fromTo(
			block,
			{ x: fromX, opacity: 0 },
			{
				x: 0,
				opacity: 1,
				duration: 0.7,
				ease: "power2.out",
				scrollTrigger: {
					trigger: block,
					start: "top 80%",
					end: "top 50%",
					scrub: 1,
					once: true,
				},
			}
		);
	});
}
